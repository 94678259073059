import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow, MarkerF } from '@react-google-maps/api';
import config from '../config';
import customMarkerIcon from '../../pin_maps.png';
import { LuNavigation, LuPhoneCall, LuShare2 } from 'react-icons/lu';
import { googleMapStyles } from './googleStyles';

const Mapa = ({ resultados, mapaCentro, selectedMarker, setSelectedMarker, setMapaCentro, zoom, setZoom }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [map, setMap] = useState(null);
    
    const [infoWindowOpen, setInfoWindowOpen] = useState(null);

    const onUnmount = useCallback(() => {
        setMap(null);
    }, []);

    const onLoad = useCallback((mapInstance) => {
        setMap(mapInstance);
    }, []);

    const handleZoomChanged = useCallback(() => {
        if (map) {
            const newZoom = map.getZoom();
            //console.log('Zoom changed:', newZoom);
            setZoom(newZoom);
        }
    }, [map]);

    /*
    const handleCenterChanged = useCallback(() => {
        if (setMapaCentro) {
            console.log("cambie+++++++++++++++++")
            console.log(setMapaCentro)
            //const newCenter = map.getCenter();
            //setZoom(14)
            //console.log('Center changed:', newCenter.toString());
            //setMapaCentro({ lat: newCenter.lat(), lng: newCenter.lng() });
        }
    }, [map, setMapaCentro]);
    */

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const mobileKeywords = ['iphone', 'ipod', 'android', 'blackberry', 'opera mini', 'windows mobile', 'windows phone', 'iemobile'];
        const isMobileDevice = mobileKeywords.some(keyword => userAgent.includes(keyword));
        setIsMobile(isMobileDevice);
    }, []);

    const containerStyle = useMemo(() => ({
        width: '100%',
        height: '850px',
        maxWidth: '100%',
    }), []);

    const containerStyleMobile = useMemo(() => ({
        ...containerStyle,
        height: '700px',
    }), [containerStyle]);

    useEffect(() => {
        if (selectedMarker) {
            setInfoWindowOpen(selectedMarker);
            setMapaCentro({ lat: parseFloat(selectedMarker.latitude), lng: parseFloat(selectedMarker.longitude) });
        } else {
            setInfoWindowOpen(null);
        }
    }, [selectedMarker, setMapaCentro]);

    const handleMarkerClick = useCallback((marker) => {

        setSelectedMarker(marker);
        setInfoWindowOpen(marker);
        setMapaCentro({ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) });
        setZoom(14)
    }, [setSelectedMarker, setMapaCentro]);

    return (
        <>

            <GoogleMap
                options={{
                    styles: googleMapStyles,
                }}
                mapContainerStyle={isMobile ? containerStyleMobile : containerStyle}
                center={mapaCentro}
                zoom={zoom}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onZoomChanged={handleZoomChanged}
                /*onCenterChanged={handleCenterChanged}*/
                onClick={() => setInfoWindowOpen(null)}
                onDrag={() => setInfoWindowOpen(null)}
            >
                {resultados.map((resultado, index) => (
                    <MarkerF
                        key={`${resultado.name}-${index}`}
                        position={{ lat: resultado.latitude, lng: resultado.longitude }}
                        icon={{
                            url: customMarkerIcon,
                            scaledSize: new window.google.maps.Size(30, 40),
                            anchor: new window.google.maps.Point(15, 0),
                        }}
                        onClick={() => handleMarkerClick(resultado)}
                    />
                ))}

                {infoWindowOpen && (
                    <InfoWindow
                        key={`infowindow-${infoWindowOpen.name}`}
                        position={{ lat: infoWindowOpen.latitude, lng: infoWindowOpen.longitude }}
                        onCloseClick={() => setInfoWindowOpen(null)}
                    >
                        <div style={{ maxWidth: '300px', textAlign: 'center', overflow: 'hidden' }}>
                            <img src={infoWindowOpen.image_url} alt="Marker Icon" style={{ width: '100%', display: 'block', marginBottom: '5px' }} />
                            <h5 style={{ marginBottom: '3px' }}>{infoWindowOpen.name}</h5>
                            <p style={{ marginBottom: '20px' }}>{infoWindowOpen.address}</p>
                            <div className='text-center links'>
                                {infoWindowOpen.google_url && (
                                    <a href={infoWindowOpen.google_url} target="_blank" rel="noopener noreferrer">
                                        <LuShare2 /> Compartir
                                    </a>
                                )}
                                <a href={`https://iurdsys.3cx.us/LiveChat85882`} target="_blank" rel="noopener noreferrer">
                                    <LuPhoneCall /> Pastor Online
                                </a>
                                <a href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${infoWindowOpen.latitude},${infoWindowOpen.longitude}`} target="_blank" rel="noopener noreferrer">
                                    <LuNavigation /> Cómo llegar
                                </a>
                            </div>
                        </div>
                    </InfoWindow>
                )}
            </GoogleMap>
        </>
    );
};

export default Mapa;
