import React, { useState } from 'react';
 // Importa los iconos de FontAwesome
import Map from './Map';

//import MapComponent from './MapComponent'; // Reemplaza 'MapComponent' con el nombre real de tu componente de mapa

const MainComponent = () => {



    return (
        <>
            <Map />
        </>
    );
};

export default MainComponent;
