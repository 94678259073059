import React, { useState, useEffect } from 'react';
import ListaDirecciones from './components/BuscadorMapa/ListaDirecciones';
import Mapa from './components/BuscadorMapa/Mapa';
import SearchComponent from './components/BuscadorMapa/SearchComponent';

import config from './components/config';

import { LuNavigation } from "react-icons/lu";
import { FaMap, FaThLarge } from 'react-icons/fa';
import { useJsApiLoader } from '@react-google-maps/api';
import ListaDireccionesImage from './components/BuscadorMapa/ListaDireccionesImage';

const libraries = ["places"];
const BuscadorMapa = () => {

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: config.googleMapsApiKey,
    libraries,
  });

  const [showMap, setShowMap] = useState(true);

  const handleMapToggle = () => {
      setShowMap(!showMap);
  };

  const buttonStyle = {
      backgroundColor: '#283E66',
      color: '#ffff'
  };


  const [resultados, setResultados] = useState([]);
  const [mapaCentro, setMapaCentro] = useState({ lat: 19.3991541, lng: -99.1852254 });
  const [zoom, setZoom] = useState(14);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedInfoWindow, setSelectedInfoWindow] = useState(null);


  const [mapaCentroInicial, setMapaCentroInicial] = useState({ lat: 19.3991541, lng: -99.1852254 });
  const [selectedMarkerInicial, setSelectedMarkerInicial] = useState(null);

  const obtenerGeolocalizacion = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          //console.log("geo:"+latitude, longitude);
          setMapaCentro({ lat: latitude, lng: longitude });
          setSelectedMarker({ latitude, longitude });
          await buscarDireccion({ lat: latitude, lng: longitude });
        },
        async(error) => {
          if (error.code === error.PERMISSION_DENIED) {
            console.error('El usuario ha denegado el acceso a la geolocalización. Habilita la geolocalización para utilizar la función.');
          } else {
            console.error('Error al obtener la geolocalización:', error.message);
          }
          await buscarDireccion(mapaCentro);
        }
      );
    } else {
      console.error('La geolocalización no está disponible en este navegador.');
    }
  };

  const buscarDireccion = async (ubicacion) => {
    setResultados([]);
    setSelectedMarker(null);

    try {
      const urlapi = `https://miembros.iurdsys.net/api/churches?latitude=${encodeURIComponent(ubicacion.lat)}&longitude=${encodeURIComponent(ubicacion.lng)}`;
      const res = await fetch(urlapi);
      //console.log(urlapi);
      if (!res.ok) {
        throw new Error('Error al obtener direcciones');
      }
  
      const data = await res.json();
  
      if (data) {
        setResultados(data.results)
        /*
        data.results.map((puntos) =>
          setResultados((prevDireccion) => [...prevDireccion, ...puntos])
        );*/
      }
      setSelectedMarker(data.results[0]);
      setMapaCentro({ lat: ubicacion.lat, lng: ubicacion.lng });

      setSelectedMarkerInicial(data.results[0]);
      setMapaCentroInicial({ lat: ubicacion.lat, lng: ubicacion.lng });   
      
    } catch (error) {
      console.error('Error en la búsqueda de dirección:', error.message);
    }
  };  

  useEffect(() => {
    obtenerGeolocalizacion();
  }, []);

  useEffect(() => {
    if (resultados.length > 0) {
      setMapaCentro({
        lat: resultados[0].latitude,
        lng: resultados[0].longitude,
      });
      setZoom(15)
    }
  }, [resultados]);

  const handleCenter = () => {

    setSelectedMarker(selectedMarkerInicial);
    setMapaCentro(mapaCentroInicial);
    setZoom(14)

  };

  const [handleAddress, setHandleAddress] = useState("");
  return (
    <>
    <div className="col-sm-12 text-center" style={{padding:'15px'}}>
                        
        {showMap ? (
            <button className="btn " style={buttonStyle} onClick={handleMapToggle}>
                <FaThLarge className="mr-2" />  Ver Iglesias
            </button>

        ) : (
            <button className="btn  me-3" style={buttonStyle} onClick={handleMapToggle}>
                <FaMap className="mr-2" /> Ver Mapa
            </button>
        )}

    </div>
    {isLoaded &&(
        <div className="container-fluid">
          
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container center">
            <SearchComponent buscarDireccion={buscarDireccion} handleCenter={handleCenter} setHandleAddress={setHandleAddress}/>
          </div>
        </nav>


        {showMap ? (
        <div className="row mt-4">
        <div className="col-lg-9 custom-row">
          <Mapa resultados={resultados} mapaCentro={mapaCentro} selectedMarker={selectedMarker} setSelectedMarker={setSelectedMarker} setMapaCentro={setMapaCentro} zoom={zoom} setZoom={setZoom}/>
        </div>
        <div className="col-lg-3 ">
        <ListaDirecciones resultados={resultados} setSelectedMarker={setSelectedMarker} setSelectedInfoWindow={setSelectedInfoWindow} setMapaCentro={setMapaCentro} setZoom={setZoom} handleAddress={handleAddress}/>
        </div>
      </div>
        ):(
          <ListaDireccionesImage resultados={resultados} setSelectedMarker={setSelectedMarker} setSelectedInfoWindow={setSelectedInfoWindow} setMapaCentro={setMapaCentro}/>
        )}

      </div>

    )}

    </>
  );
};

export default BuscadorMapa;
