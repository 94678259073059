import React from 'react';

import { LuClock, LuClock1, LuClock10 } from 'react-icons/lu';

const daysInSpanish = {
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  sunday: 'Domingo'
};

const daysInEnglish = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday'
};

// Function to get today's day in English
const getToday = () => {
  const date = new Date();
  const dayIndex = date.getDay(); // 0 (Sunday) to 6 (Saturday)
  return daysInEnglish[dayIndex];
};

const MeetingSchedules = ({ schedules }) => {
  const today = getToday();

  return (
    <div>
      {Object.keys(schedules).map((day) => (
        <div key={day} className={day === today ? 'highlight' : ''}>
          
          {Array.isArray(schedules[day]) && schedules[day].length > 0 && (
            <div className="m-date ">
              <span className='m-date-day'>{daysInSpanish[day]} </span>
              <div className='m-date-hour'>
              {schedules[day].map((time, index) => (
                <span key={index} >
                   {time}
                </span>
              ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MeetingSchedules;
