import React, { useState } from 'react';
import { LoadScript } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import config from '../config';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { FaMapMarker } from 'react-icons/fa'; 

import { FaStreetView, FaCrosshairs } from 'react-icons/fa'; 
import iconMarker from '../../pin_maps.png'
const libraries = ["places"]; // Define las bibliotecas como una constante fuera del componente

const SearchComponent = ({ buscarDireccion ,handleCenter, setHandleAddress }) => {
    const [address, setAddress] = useState('');

    const handleSelect = async (selectedAddress) => {
        try {
            setHandleAddress(address)
            const results = await geocodeByAddress(selectedAddress);
            const latLng = await getLatLng(results[0]);
            buscarDireccion(latLng);
        } catch (error) {
            console.error('Error fetching coordinates', error);
        }
    };

    return (
        <div className='col'>
            
                <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className='col'>
                        <InputGroup>                            
                            <FormControl
                                {...getInputProps({
                                    placeholder: 'Ingrese una ciudad o su dirección',
                                    className: 'input-search ',
                                })}
                            />
                            <Button onClick={handleCenter} className='icon-search' style={{ color: 'white' }}>
                                <FaCrosshairs />  
                            </Button>
                        </InputGroup>
                        <div className="autocomplete-dropdown-container">
                            {loading && <div>Cargando...</div>}
                            {suggestions.map((suggestion, index) => {
                                const style = {
                                    backgroundColor: suggestion.active ? '#73c9eb' : '#fff',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '8px',
                                    borderBottom: '1px solid #ccc',
                                };

                                return (
                                    <div key={index} {...getSuggestionItemProps(suggestion, { style })}>
                                        <FaStreetView style={{ marginRight: '8px' }} /> {/* Icono a la izquierda */}
                                        {suggestion.description}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    )}
                </PlacesAutocomplete>
            
        </div>
    );
};

export default SearchComponent;
