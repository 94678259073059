import React, { useState, useEffect } from 'react';
import { Accordion, Badge } from 'react-bootstrap';
import { LuNavigation, LuMapPin, LuPhoneCall, LuShare2 } from "react-icons/lu";
import MeetingSchedules from './MeetingSchedules';

const ListaDireccionesImage = ({ resultados, setSelectedMarker, setSelectedInfoWindow,setMapaCentro }) => {
  const itemsPorPagina = 6;
  const [paginaActual, setPaginaActual] = useState(1);
  const [paginasMostradas, setPaginasMostradas] = useState([1, 2, 3, 4, 5]);

  const indicePrimerElemento = (paginaActual - 1) * itemsPorPagina;
  const indiceUltimoElemento = indicePrimerElemento + itemsPorPagina;
  const resultadosPagina = resultados.slice(indicePrimerElemento, indiceUltimoElemento);

  const totalPaginas = Math.ceil(resultados.length / itemsPorPagina);

  const handlePaginaCambio = (numeroPagina) => {
    setPaginaActual(numeroPagina);
    actualizarPaginasMostradas(numeroPagina);
  };

  const actualizarPaginasMostradas = (numeroPagina) => {
    if (numeroPagina <= 5) {
      setPaginasMostradas([1, 2, 3, 4, 5]);
    } else {
      let nuevasPaginasMostradas = [];
      for (let i = numeroPagina - 1; i <= numeroPagina + 1; i++) {
        nuevasPaginasMostradas.push(i);
      }
      setPaginasMostradas(nuevasPaginasMostradas);
    }
  };

  const centrarEnMapa = (latitud, longitud, result) => {
    setMapaCentro({ lat: latitud, lng: longitud });
    setSelectedMarker(result);
    setSelectedInfoWindow(result);
  };

  const abrirComoLlegarGoogleMaps = ({latitude,longitude,direccion}) => {
    const direccionFormateada = encodeURIComponent(direccion);
    window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${latitude},${longitude}`, '_blank');
  };

  useEffect(() => {
    // Cuando los resultados cambien, establecer la página actual en 1
    setPaginaActual(1);
  }, [resultados]);

  return (
    <div className="row">
      {resultadosPagina.map((resultado, index) => (
  
      <div key={`${resultado.name}-${index}`} className="col-lg-4 col-md-6 my-3">
        <div className="card h-100 shadow-sm" style={{ margin: '0 15px', borderRadius: '10px' }}>
          <div className="img-container" style={{ height: '362px', overflow: 'hidden', borderRadius: '10px 10px 0 0' }}>
            <img src={resultado.image_url} className="card-img-top" style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }} alt="Imagen de Resultado" />
          </div>
          <div className="card-body">
            <div className="fw-bold" onClick={() => centrarEnMapa(resultado.latitude, resultado.longitude, resultado)} style={{ cursor: 'pointer' }}>
              {resultado.name}
            </div>
            <div className="adress-box small mt-2">
              <div className="icon-label">
                <LuMapPin /> {resultado.address}
              </div>
            </div>

            <div className='mt-2 text-center links'>

              {resultado.google_url && (
              <a href={resultado.google_url} target="_blank">
                  <LuShare2 /> Compartir
              </a>
              )}
              <a href={`https://iurdsys.3cx.us/LiveChat85882`} target="_blank">
                  <LuPhoneCall /> Pastor Online
              </a>

            </div>

          </div>
          
          <div className="card-body">
              <Accordion className="custom-accordion" defaultActiveKey="0">
                <Accordion.Item eventKey={index} key={index}>
                  <Accordion.Header>Horarios</Accordion.Header>
                  <Accordion.Body>
                    <div className="accordion-content">
                      <MeetingSchedules schedules={resultado.schedules} />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
          </div>
          <div className="card-footer d-flex justify-content-between align-items-center">
            <Badge bg="danger">{` ${Math.round(resultado.distance)} KM`}</Badge>
            <button className="btn btn-danger" onClick={() => abrirComoLlegarGoogleMaps(resultado)}>
              <LuNavigation /> Cómo llegar
            </button>
          </div>
        </div>
      </div>
   
      ))}
  
      {totalPaginas > 1 && (
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center pagination-sm">
            <li className="page-item">
              <button className="page-link link-danger" onClick={() => handlePaginaCambio(paginaActual - 1)} disabled={paginaActual === 1}>
                Anterior
              </button>
            </li>
            {paginasMostradas.map((numeroPagina) => (
              <li key={numeroPagina} className={`page-item ${numeroPagina === paginaActual ? 'active' : ''}`}>
                <button className="page-link link-danger" onClick={() => handlePaginaCambio(numeroPagina)}>
                  {numeroPagina}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button className="page-link link-danger" onClick={() => handlePaginaCambio(paginaActual + 1)} disabled={paginaActual === totalPaginas}>
                Siguiente
              </button>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
  
  
};

export default ListaDireccionesImage;
