import React, { useState, useEffect } from 'react';
import { Accordion, Badge } from 'react-bootstrap';
import { LuNavigation, LuPhoneCall, LuShare2 } from "react-icons/lu";
import MeetingSchedules from './MeetingSchedules';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

const ListaDirecciones = ({ resultados, setSelectedMarker, setSelectedInfoWindow,setMapaCentro, setZoom, handleAddress }) => {
  const itemsPorPagina = 5;
  const [paginaActual, setPaginaActual] = useState(1);
  const [paginasMostradas, setPaginasMostradas] = useState([1, 2, 3, 4, 5]);

  const indicePrimerElemento = (paginaActual - 1) * itemsPorPagina;
  const indiceUltimoElemento = indicePrimerElemento + itemsPorPagina;
  const resultadosPagina = resultados.slice(indicePrimerElemento, indiceUltimoElemento);

  const totalPaginas = Math.ceil(resultados.length / itemsPorPagina);

  const handlePaginaCambio = (numeroPagina) => {
    setPaginaActual(numeroPagina);
    actualizarPaginasMostradas(numeroPagina);
  };

  const actualizarPaginasMostradas = (numeroPagina) => {
    if (numeroPagina <= 5) {
      setPaginasMostradas([1, 2, 3, 4, 5]);
    } else {
      let nuevasPaginasMostradas = [];
      for (let i = numeroPagina - 1; i <= numeroPagina + 1; i++) {
        nuevasPaginasMostradas.push(i);
      }
      setPaginasMostradas(nuevasPaginasMostradas);
    }
  };

  const centrarEnMapa = (latitud, longitud, result) => {
    setMapaCentro({ lat: latitud, lng: longitud });
    setSelectedMarker(result);
    setSelectedInfoWindow(result);
    setZoom(16)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const abrirComoLlegarGoogleMaps = ({latitude,longitude,direccion}) => {
    const direccionFormateada = encodeURIComponent(direccion);
    window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${latitude},${longitude}`, '_blank');
  };

  useEffect(() => {
    // Cuando los resultados cambien, establecer la página actual en 1
    setPaginaActual(1);
  }, [resultados]);

  return (
    <ol className="list-group">
      <h5>Iglesias Cercanas a: {handleAddress} </h5>
      {resultadosPagina.map((resultado, index) => (
        <li key={`${resultado.name}-${index}`} className="list-group-item d-flex justify-content-between align-items-start">
          
          <div className="ms-2 me-auto w-100">
            <div style={{ position: 'relative' }}>
              <div className="fw-bold" onClick={() => { centrarEnMapa(resultado.latitude, resultado.longitude, resultado); }} style={{ paddingBottom:10 , paddingRight:57 , cursor: 'pointer', zIndex: 1 }}>
                {resultado.name}
              </div>
              <Badge style={{ position: 'absolute', top: 0, right: 4, zIndex: 0 }} bg="danger">{`${resultado.distance} KM`}</Badge>
            </div>

            <div className="adress-box small" onClick={() => { centrarEnMapa(resultado.latitude, resultado.longitude, resultado); }} style={{ cursor: 'pointer' }}>
              <div className="icon-label" >{resultado.address}</div>
            </div>

            <br/>
            <div className='text-center linksCard'>
              {resultado.google_url && (
                <a href={resultado.google_url} target="_blank">
                  <LuShare2 /> Compartir
                </a>
              )}
              <a href={`https://iurdsys.3cx.us/LiveChat85882`} target="_blank">
                <LuPhoneCall /> Pastor Online
              </a>
              <a href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${resultado.latitude},${resultado.longitude}`}  target="_blank">
                <LuNavigation /> Cómo llegar
              </a>
            </div>

            <div className="w-100">
              <Accordion className="custom-accordion" defaultActiveKey="0">
                <Accordion.Item eventKey={index} key={index}>
                  <Accordion.Header>Horarios</Accordion.Header>
                  <Accordion.Body>
                    <div className="accordion-content">
                      <MeetingSchedules schedules={resultado.schedules} />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

          </div>

        </li>

      ))}

      {totalPaginas > 1 && (
        <nav aria-label="Page navigation example" style={{paddingBottom:'100px'}}>
          <ul className="pagination justify-content-center pagination-sm" >
            <li className="page-item">
              <button className="page-link link-danger" onClick={() => handlePaginaCambio(paginaActual - 1)} disabled={paginaActual === 1}>
                Anterior
              </button>
            </li>
            {paginasMostradas.map((numeroPagina) => (
              <li key={numeroPagina} className={`page-item ${numeroPagina === paginaActual ? 'active' : ''}`}>
                <button className="page-link link-danger" onClick={() => handlePaginaCambio(numeroPagina)}>
                  {numeroPagina}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button className="page-link link-danger" onClick={() => handlePaginaCambio(paginaActual + 1)} disabled={paginaActual === totalPaginas}>
                Siguiente
              </button>
            </li>
          </ul>
        </nav>
      )}
    </ol>
  );
};

export default ListaDirecciones;
